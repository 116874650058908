import React from 'react'
import logger from 'src/utils/loggerClient'
import { LoggerContext } from './useLogger'

const LoggerProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
  )
}

export default React.memo(LoggerProvider)
