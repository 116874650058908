import { datadogLogs } from '@datadog/browser-logs'
import config from '../config'

datadogLogs.init({
  clientToken: config.datadog.clientToken,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: config.env,
  service: 'spotme-client',
  version: config.version
})

export default config.env === 'local' ? console : datadogLogs.logger
